<template>
	<v-container>
		<portal to="app-bar-title">
			{{ subject.name }}
		</portal>

		<portal to="header-content">
			<v-spacer></v-spacer>

			<v-btn
				color="success"
				class="text-none"
				:fab="!$vuetify.breakpoint.mdAndUp"
				:x-small="!$vuetify.breakpoint.mdAndUp"
				depressed
				@click="addTest">
				<v-icon :left="$vuetify.breakpoint.mdAndUp">mdi-plus</v-icon>
				<span v-if="$vuetify.breakpoint.mdAndUp">
					Додати новий тест
				</span>
			</v-btn>
		</portal>

		<v-row>
			<v-col
				v-for="test in tests"
				:key="test.id"
				cols="12"
				sm="6"
				md="4"
				lg="3">
				<v-card
					class="elevation-24 d-flex flex-column"
					height="100%"
					:to="{
						name: 'test',
						params: {
							id: test.id,
						},
					}">
					<v-card-title class="w-break-normal w-whitespace-normal">
						{{ test.name }}
					</v-card-title>
					<v-card-text>
						<v-chip
							:color="test.is_active ? 'success' : 'error'"
							small>
							{{ test.is_active }}
						</v-chip>
					</v-card-text>

					<v-card-actions
						class="mt-auto">
						<v-spacer></v-spacer>
						<file-downloader
							:name="`${test.name}.docx`"
							:api-call="() => Test.export(test.id, { format: 'doc' })">
							<template>
								<v-icon>
									mdi-file-download
								</v-icon>
							</template>
						</file-downloader>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>

		<v-dialog
			v-model="createDialog"
			:fullscreen="$vuetify.breakpoint.xsOnly"
			max-width="800px"
			persistent>

			<v-card
				:loading="loading"
				outlined>
				<validation-observer
					ref="observer"
					v-slot="{ invalid, handleSubmit }">
					<v-form ref="editForm"
						@submit.prevent="handleSubmit(saveTest)"
						@reset.native="cancel">

						<v-card-title>Тест</v-card-title>
						<v-divider></v-divider>

						<v-card-text class="mt-5">
							<v-row>
								<v-col
									cols="12"
									md="9"
									class="py-0">
									<vv-text-field
										v-model="test.name"
										label="Назва тесту"
										rules="required"
										name="name"
										required
										outlined
										dense>
									</vv-text-field>
								</v-col>
								<v-col
									cols="12"
									md="3"
									class="py-0">
									<v-switch
										v-model="test.is_active"
										label="Активне"
										class="mt-1"
										inset>
									</v-switch>
								</v-col>
								<v-col
									cols="12"
									md="6"
									class="py-0">
									<vv-autocomplete
										v-model="test.category_id"
										:items="categories"
										item-text="name"
										item-value="id"
										label="Категорія тестів"
										name="category"
										outlined
										dense>
									</vv-autocomplete>
								</v-col>
							</v-row>
						</v-card-text>

						<v-divider></v-divider>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								type="reset"
								depressed>
								Відмінити
							</v-btn>
							<v-btn
								type="submit"
								:disabled="invalid"
								color="primary"
								depressed>
								Зберегти
							</v-btn>
						</v-card-actions>
					</v-form>
				</validation-observer>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'

import Subject from '../models/Subject'
import Test from '../models/Test'

export default {
	props: {
		id: {
			type: Number,
			required: true,
		},
	},
	data: () => ({
		Test,
		test: {},
		search: '',
		loading: false,
		createDialog: false,
	}),
	computed: {
		subject() {
			return Subject.find(this.id) || {}
		},
		categories() {
			return this.subject?.categories || []
		},
		tests() {
			return Test.all()
		},
	},
	methods: {
		...mapActions('notification', [
			'notify',
		]),

		async loadSubject() {
			try {
				await Subject.fetchGet(this.id)
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити предмет',
					color: 'error',
				}, { root: true })
			}
		},

		async loadTests() {
			try {
				this.loading = true
				await Test.fetchGetAllBySubject(this.id)
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список тестів',
					color: 'error',
				}, { root: true })
			} finally {
				this.loading = false
			}
		},

		async saveTest() {
			try {
				this.loading = true
				await Test.fetchCreateOrUpdate(this.test)
				this.$refs.editForm.reset()
				this.$refs.observer.reset()
				this.createDialog = false
				this.notify({
					text: 'Тест успішно збережено',
					color: 'success',
				}, { root: true })
				this.loadTests()
			} catch (error) {
				this.notify({
					text: 'Не вдалося зберегти тест',
					color: 'error',
				}, { root: true })
			} finally {
				this.loading = false
			}
		},

		addTest() {
			this.test = new Test()
			this.createDialog = true
		},

		cancel() {
			this.test = new Test()
			this.$refs.editForm.reset()
			this.$refs.observer.reset()
			this.createDialog = false
		},
	},
	mounted() {
		this.loadSubject()
		this.loadTests()
	},
}
</script>